export const staticData = [
    {
        name: 'ООО «Геотехпроект»',
        inn: '2463219097',
        reason: '3',
        reasonText: '<p>3. Нарушение условий договора, повлекшее за&nbsp;собой причинение убытков, начисление\n' +
        'неустойки либо возникновение иных требований ПАО &laquo;ГМК &laquo;Норильский никель&raquo;\n' +
        'или российской организации корпоративной структуры, входящей в&nbsp;Группу компаний &laquo;Норильский\n' +
        'никель&raquo;, к&nbsp;контрагенту, не&nbsp;урегулированных во&nbsp;внесудебном порядке,\n' +
        'установленном действующим законодательством либо договором.</p>',
        date: '13.09.2024'
    },
    {
        name: 'ООО «СК-Альфа»',
        inn: '2457084123',
        reason: '3',
        reasonText: '<p>3. Нарушение условий договора, повлекшее за&nbsp;собой причинение убытков, начисление\n' +
        'неустойки либо возникновение иных требований ПАО &laquo;ГМК &laquo;Норильский никель&raquo;\n' +
        'или российской организации корпоративной структуры, входящей в&nbsp;Группу компаний &laquo;Норильский\n' +
        'никель&raquo;, к&nbsp;контрагенту, не&nbsp;урегулированных во&nbsp;внесудебном порядке,\n' +
        'установленном действующим законодательством либо договором.</p>',
        date: '13.09.2024'
    },
    {
        name: 'ООО «РЦС»',
        inn: '7814503581',
        reason: '3',
        reasonText: '<p>3. Нарушение условий договора, повлекшее за&nbsp;собой причинение убытков, начисление\n' +
        'неустойки либо возникновение иных требований ПАО &laquo;ГМК &laquo;Норильский никель&raquo;\n' +
        'или российской организации корпоративной структуры, входящей в&nbsp;Группу компаний &laquo;Норильский\n' +
        'никель&raquo;, к&nbsp;контрагенту, не&nbsp;урегулированных во&nbsp;внесудебном порядке,\n' +
        'установленном действующим законодательством либо договором.</p>',
        date: '09.09.2024'
    },
    {
        name: 'ООО «ГЕНЕЗИС ПРОЕКТ»',
        inn: '5190054096',
        reason: '3',
        reasonText: '<p>3. Нарушение условий договора, повлекшее за&nbsp;собой причинение убытков, начисление\n' +
        'неустойки либо возникновение иных требований ПАО &laquo;ГМК &laquo;Норильский никель&raquo;\n' +
        'или российской организации корпоративной структуры, входящей в&nbsp;Группу компаний &laquo;Норильский\n' +
        'никель&raquo;, к&nbsp;контрагенту, не&nbsp;урегулированных во&nbsp;внесудебном порядке,\n' +
        'установленном действующим законодательством либо договором.</p>',
        date: '14.08.2024'
    },
    {
        name: 'ООО «АСУ-Инжиниринг»',
        inn: '0274191220',
        reason: '3',
        reasonText: '<p>3. Нарушение условий договора, повлекшее за&nbsp;собой причинение убытков, начисление\n' +
        'неустойки либо возникновение иных требований ПАО &laquo;ГМК &laquo;Норильский никель&raquo;\n' +
        'или российской организации корпоративной структуры, входящей в&nbsp;Группу компаний &laquo;Норильский\n' +
        'никель&raquo;, к&nbsp;контрагенту, не&nbsp;урегулированных во&nbsp;внесудебном порядке,\n' +
        'установленном действующим законодательством либо договором.</p>',
        date: '14.08.2024'
    },
    {
        name: 'ООО «Гидротехнологии Сибири»',
        inn: '3808099451',
        reason: '3',
        reasonText: '<p>3. Нарушение условий договора, повлекшее за&nbsp;собой причинение убытков, начисление\n' +
        'неустойки либо возникновение иных требований ПАО &laquo;ГМК &laquo;Норильский никель&raquo;\n' +
        'или российской организации корпоративной структуры, входящей в&nbsp;Группу компаний &laquo;Норильский\n' +
        'никель&raquo;, к&nbsp;контрагенту, не&nbsp;урегулированных во&nbsp;внесудебном порядке,\n' +
        'установленном действующим законодательством либо договором.</p>',
        date: '06.08.2024'
    },
    {
        name: 'ООО «Грос-Строй»',
        inn: '3245508830',
        reason: '2, 3',
        reasonText: '<p>2. Предоставление заведомо недостоверных сведений для участия в&nbsp;закупочных процедурах ПАО «ГМК «Норильский никель» или российской организации корпоративной структуры, входящей в&nbsp;Группу компаний «Норильский никель».</p><p>3. Нарушение условий договора, повлекшее за&nbsp;собой причинение убытков, начисление\n' +
        'неустойки либо возникновение иных требований ПАО &laquo;ГМК &laquo;Норильский никель&raquo;\n' +
        'или российской организации корпоративной структуры, входящей в&nbsp;Группу компаний &laquo;Норильский\n' +
        'никель&raquo;, к&nbsp;контрагенту, не&nbsp;урегулированных во&nbsp;внесудебном порядке,\n' +
        'установленном действующим законодательством либо договором.</p>',
        date: '06.08.2024'
    },
    {
        name: 'ООО «Сибкомплектсервис»',
        inn: '7736606570',
        reason: '3',
        reasonText: '<p>3. Нарушение условий договора, повлекшее за&nbsp;собой причинение убытков, начисление\n' +
        'неустойки либо возникновение иных требований ПАО &laquo;ГМК &laquo;Норильский никель&raquo;\n' +
        'или российской организации корпоративной структуры, входящей в&nbsp;Группу компаний &laquo;Норильский\n' +
        'никель&raquo;, к&nbsp;контрагенту, не&nbsp;урегулированных во&nbsp;внесудебном порядке,\n' +
        'установленном действующим законодательством либо договором.</p>',
        date: '27.06.2024'
    },
    {
        name: 'ООО «СТАТУС-М»',
        inn: '9728105870',
        reason: '3',
        reasonText: '<p>3. Нарушение условий договора, повлекшее за&nbsp;собой причинение убытков, начисление\n' +
        'неустойки либо возникновение иных требований ПАО &laquo;ГМК &laquo;Норильский никель&raquo;\n' +
        'или российской организации корпоративной структуры, входящей в&nbsp;Группу компаний &laquo;Норильский\n' +
        'никель&raquo;, к&nbsp;контрагенту, не&nbsp;урегулированных во&nbsp;внесудебном порядке,\n' +
        'установленном действующим законодательством либо договором.</p>',
        date: '26.06.2024'
    },
    {
        name: 'ООО «ЧЕСТЕР»',
        inn: '9704222800',
        reason: '3',
        reasonText: '<p>3. Нарушение условий договора, повлекшее за&nbsp;собой причинение убытков, начисление\n' +
        'неустойки либо возникновение иных требований ПАО &laquo;ГМК &laquo;Норильский никель&raquo;\n' +
        'или российской организации корпоративной структуры, входящей в&nbsp;Группу компаний &laquo;Норильский\n' +
        'никель&raquo;, к&nbsp;контрагенту, не&nbsp;урегулированных во&nbsp;внесудебном порядке,\n' +
        'установленном действующим законодательством либо договором.</p>',
        date: '26.06.2024'
    },
    {
        name: 'ООО «НТЦ «Промтех»',
        inn: '7843007669',
        reason: '3',
        reasonText: '<p>3. Нарушение условий договора, повлекшее за&nbsp;собой причинение убытков, начисление\n' +
        'неустойки либо возникновение иных требований ПАО &laquo;ГМК &laquo;Норильский никель&raquo;\n' +
        'или российской организации корпоративной структуры, входящей в&nbsp;Группу компаний &laquo;Норильский\n' +
        'никель&raquo;, к&nbsp;контрагенту, не&nbsp;урегулированных во&nbsp;внесудебном порядке,\n' +
        'установленном действующим законодательством либо договором.</p>',
        date: '19.06.2024'
    },
    {
        name: 'ООО «Азимут»',
        inn: '0278205681',
        reason: '1',
        reasonText: '<p>1. Уклонение победителя закупки от&nbsp;заключения договора с&nbsp;ПАО &laquo;ГМК &laquo;Норильский никель&raquo; или российской организацией корпоративной структуры, входящей в&nbsp;Группу компаний &laquo;Норильский никель&raquo;.</p>',
        date: '03.06.2024'
    },
    {
        name: 'ООО «Север-строй»',
        inn: '5109095850',
        reason: '3',
        reasonText: '<p>3. Нарушение условий договора, повлекшее за&nbsp;собой причинение убытков, начисление\n' +
        'неустойки либо возникновение иных требований ПАО &laquo;ГМК &laquo;Норильский никель&raquo;\n' +
        'или российской организации корпоративной структуры, входящей в&nbsp;Группу компаний &laquo;Норильский\n' +
        'никель&raquo;, к&nbsp;контрагенту, не&nbsp;урегулированных во&nbsp;внесудебном порядке,\n' +
        'установленном действующим законодательством либо договором.</p>',
        date: '27.05.2024'
    },
    {
        name: 'ООО «УК «Жилтехник»',
        inn: '9710063121',
        reason: '3',
        reasonText: '<p>3. Нарушение условий договора, повлекшее за&nbsp;собой причинение убытков, начисление\n' +
        'неустойки либо возникновение иных требований ПАО &laquo;ГМК &laquo;Норильский никель&raquo;\n' +
        'или российской организации корпоративной структуры, входящей в&nbsp;Группу компаний &laquo;Норильский\n' +
        'никель&raquo;, к&nbsp;контрагенту, не&nbsp;урегулированных во&nbsp;внесудебном порядке,\n' +
        'установленном действующим законодательством либо договором.</p>',
        date: '27.05.2024'
    },
    {
        name: 'ООО «ТРЕСТ ЗСГС»',
        inn: '8602289578',
        reason: '3',
        reasonText: '<p>3. Нарушение условий договора, повлекшее за&nbsp;собой причинение убытков, начисление\n' +
        'неустойки либо возникновение иных требований ПАО &laquo;ГМК &laquo;Норильский никель&raquo;\n' +
        'или российской организации корпоративной структуры, входящей в&nbsp;Группу компаний &laquo;Норильский\n' +
        'никель&raquo;, к&nbsp;контрагенту, не&nbsp;урегулированных во&nbsp;внесудебном порядке,\n' +
        'установленном действующим законодательством либо договором.</p>',
        date: '27.05.2024'
    },
    {
        name: 'ООО «Новая электрическая компания»',
        inn: '7731469506',
        reason: '3',
        reasonText: '<p>3. Нарушение условий договора, повлекшее за&nbsp;собой причинение убытков, начисление\n' +
        'неустойки либо возникновение иных требований ПАО &laquo;ГМК &laquo;Норильский никель&raquo;\n' +
        'или российской организации корпоративной структуры, входящей в&nbsp;Группу компаний &laquo;Норильский\n' +
        'никель&raquo;, к&nbsp;контрагенту, не&nbsp;урегулированных во&nbsp;внесудебном порядке,\n' +
        'установленном действующим законодательством либо договором.</p>',
        date: '25.04.2024'
    },
    {
        name: 'ИП Кривенко И.В.',
        inn: '510704213661',
        reason: '1',
        reasonText: '<p>1. Уклонение победителя закупки от&nbsp;заключения договора с&nbsp;ПАО &laquo;ГМК &laquo;Норильский никель&raquo; или российской организацией корпоративной структуры, входящей в&nbsp;Группу компаний &laquo;Норильский никель&raquo;.</p>',
        date: '18.03.2024'
    },
    {
        name: 'ООО «Артстройкомплект»',
        inn: '2440007730',
        reason: '3',
        reasonText: '<p>3. Нарушение условий договора, повлекшее за&nbsp;собой причинение убытков, начисление\n' +
        'неустойки либо возникновение иных требований ПАО &laquo;ГМК &laquo;Норильский никель&raquo;\n' +
        'или российской организации корпоративной структуры, входящей в&nbsp;Группу компаний &laquo;Норильский\n' +
        'никель&raquo;, к&nbsp;контрагенту, не&nbsp;урегулированных во&nbsp;внесудебном порядке,\n' +
        'установленном действующим законодательством либо договором.</p>',
        date: '18.03.2024'
    },
    {
        name: 'ООО «Эколос-Проектстрой»',
        inn: '6311135876',
        reason: '3',
        reasonText: '<p>3. Нарушение условий договора, повлекшее за&nbsp;собой причинение убытков, начисление\n' +
        'неустойки либо возникновение иных требований ПАО &laquo;ГМК &laquo;Норильский никель&raquo;\n' +
        'или российской организации корпоративной структуры, входящей в&nbsp;Группу компаний &laquo;Норильский\n' +
        'никель&raquo;, к&nbsp;контрагенту, не&nbsp;урегулированных во&nbsp;внесудебном порядке,\n' +
        'установленном действующим законодательством либо договором.</p>',
        date: '13.03.2024'
    },
    {
        name: 'ООО «СБК»',
        inn: '7841035036',
        reason: '3',
        reasonText: '<p>3. Нарушение условий договора, повлекшее за&nbsp;собой причинение убытков, начисление\n' +
        'неустойки либо возникновение иных требований ПАО &laquo;ГМК &laquo;Норильский никель&raquo;\n' +
        'или российской организации корпоративной структуры, входящей в&nbsp;Группу компаний &laquo;Норильский\n' +
        'никель&raquo;, к&nbsp;контрагенту, не&nbsp;урегулированных во&nbsp;внесудебном порядке,\n' +
        'установленном действующим законодательством либо договором.</p>',
        date: '29.02.2024'
    },
    {
        name: 'ООО «ПРО-ЭКСПЕРТ»',
        inn: '7801289267',
        reason: '3',
        reasonText: '<p>3. Нарушение условий договора, повлекшее за&nbsp;собой причинение убытков, начисление\n' +
        'неустойки либо возникновение иных требований ПАО &laquo;ГМК &laquo;Норильский никель&raquo;\n' +
        'или российской организации корпоративной структуры, входящей в&nbsp;Группу компаний &laquo;Норильский\n' +
        'никель&raquo;, к&nbsp;контрагенту, не&nbsp;урегулированных во&nbsp;внесудебном порядке,\n' +
        'установленном действующим законодательством либо договором.</p>',
        date: '29.02.2024'
    },
    {
        name: 'ООО «ЕРСМ Сибири»',
        inn: '2463242025',
        reason: '3',
        reasonText: '<p>3. Нарушение условий договора, повлекшее за&nbsp;собой причинение убытков, начисление\n' +
        'неустойки либо возникновение иных требований ПАО &laquo;ГМК &laquo;Норильский никель&raquo;\n' +
        'или российской организации корпоративной структуры, входящей в&nbsp;Группу компаний &laquo;Норильский\n' +
        'никель&raquo;, к&nbsp;контрагенту, не&nbsp;урегулированных во&nbsp;внесудебном порядке,\n' +
        'установленном действующим законодательством либо договором.</p>',
        date: '21.12.2023'
    },
    {
        name: 'ООО «Нефте трейд»',
        inn: '4345479462',
        reason: '3',
        reasonText: '<p>3. Нарушение условий договора, повлекшее за&nbsp;собой причинение убытков, начисление\n' +
        'неустойки либо возникновение иных требований ПАО &laquo;ГМК &laquo;Норильский никель&raquo;\n' +
        'или российской организации корпоративной структуры, входящей в&nbsp;Группу компаний &laquo;Норильский\n' +
        'никель&raquo;, к&nbsp;контрагенту, не&nbsp;урегулированных во&nbsp;внесудебном порядке,\n' +
        'установленном действующим законодательством либо договором.</p>',
        date: '18.12.2023'
    },
    {
        name: 'ООО «Экспресс»',
        inn: '7515004344',
        reason: '3',
        reasonText: '<p>3. Нарушение условий договора, повлекшее за&nbsp;собой причинение убытков, начисление\n' +
        'неустойки либо возникновение иных требований ПАО &laquo;ГМК &laquo;Норильский никель&raquo;\n' +
        'или российской организации корпоративной структуры, входящей в&nbsp;Группу компаний &laquo;Норильский\n' +
        'никель&raquo;, к&nbsp;контрагенту, не&nbsp;урегулированных во&nbsp;внесудебном порядке,\n' +
        'установленном действующим законодательством либо договором.</p>',
        date: '10.11.2023'
    },
    {
        name: 'ООО «МСБ-ИНЖИНИРИНГ»',
        inn: '7444063904',
        reason: '1',
        reasonText: '<p>1. Уклонение победителя закупки от&nbsp;заключения договора с&nbsp;ПАО &laquo;ГМК &laquo;Норильский никель&raquo; или российской организацией корпоративной структуры, входящей в&nbsp;Группу компаний &laquo;Норильский никель&raquo;.</p>',
        date: '07.11.2023'
    },
    {
        name: 'ИП Асланиди Л.Т.',
        inn: '245722725944',
        reason: '3',
        reasonText: '<p>3. Нарушение условий договора, повлекшее за&nbsp;собой причинение убытков, начисление\n' +
        'неустойки либо возникновение иных требований ПАО &laquo;ГМК &laquo;Норильский никель&raquo;\n' +
        'или российской организации корпоративной структуры, входящей в&nbsp;Группу компаний &laquo;Норильский\n' +
        'никель&raquo;, к&nbsp;контрагенту, не&nbsp;урегулированных во&nbsp;внесудебном порядке,\n' +
        'установленном действующим законодательством либо договором.</p>',
        date: '31.10.2023'
    },
    {
        name: 'ООО «М.СТРОЙ»',
        inn: '7703369980',
        reason: '1',
        reasonText: '<p>1. Уклонение победителя закупки от&nbsp;заключения договора с&nbsp;ПАО &laquo;ГМК &laquo;Норильский никель&raquo; или российской организацией корпоративной структуры, входящей в&nbsp;Группу компаний &laquo;Норильский никель&raquo;.</p>',
        date: '27.10.2023'
    },
    {
        name: 'ООО ТА «Панорама Петрозаводск»',
        inn: '1001233433',
        reason: '3',
        reasonText: '<p>3. Нарушение условий договора, повлекшее за&nbsp;собой причинение убытков, начисление\n' +
        'неустойки либо возникновение иных требований ПАО &laquo;ГМК &laquo;Норильский никель&raquo;\n' +
        'или российской организации корпоративной структуры, входящей в&nbsp;Группу компаний &laquo;Норильский\n' +
        'никель&raquo;, к&nbsp;контрагенту, не&nbsp;урегулированных во&nbsp;внесудебном порядке,\n' +
        'установленном действующим законодательством либо договором.</p>',
        date: '25.10.2023'
    },
    {
        name: 'ООО «Юность плюс»',
        inn: '2457074157',
        reason: '3',
        reasonText: '<p>3. Нарушение условий договора, повлекшее за&nbsp;собой причинение убытков, начисление\n' +
        'неустойки либо возникновение иных требований ПАО &laquo;ГМК &laquo;Норильский никель&raquo;\n' +
        'или российской организации корпоративной структуры, входящей в&nbsp;Группу компаний &laquo;Норильский\n' +
        'никель&raquo;, к&nbsp;контрагенту, не&nbsp;урегулированных во&nbsp;внесудебном порядке,\n' +
        'установленном действующим законодательством либо договором.</p>',
        date: '23.10.2023'
    },
    {
        name: 'ООО «Дженси Групп»',
        inn: '7729432298',
        reason: '1',
        reasonText: '<p>1. Уклонение победителя закупки от&nbsp;заключения договора с&nbsp;ПАО &laquo;ГМК &laquo;Норильский никель&raquo; или российской организацией корпоративной структуры, входящей в&nbsp;Группу компаний &laquo;Норильский никель&raquo;.</p>',
        date: '09.10.2023'
    },
    {
        name: 'ООО «СПЭР»',
        inn: '2457084444',
        reason: '1, 3',
        reasonText: '<p>1. Уклонение победителя закупки от&nbsp;заключения договора с&nbsp;ПАО &laquo;ГМК &laquo;Норильский никель&raquo; или российской организацией корпоративной структуры, входящей в&nbsp;Группу компаний &laquo;Норильский никель&raquo;.</p><p>3. Нарушение условий договора, повлекшее за&nbsp;собой причинение убытков, начисление\n' +
        'неустойки либо возникновение иных требований ПАО &laquo;ГМК &laquo;Норильский никель&raquo;\n' +
        'или российской организации корпоративной структуры, входящей в&nbsp;Группу компаний &laquo;Норильский\n' +
        'никель&raquo;, к&nbsp;контрагенту, не&nbsp;урегулированных во&nbsp;внесудебном порядке,\n' +
        'установленном действующим законодательством либо договором.</p>',
        date: '20.09.2023'
    },
    {
        name: 'Общество с ограниченной ответственностью "Строй Металл Престиж"',
        inn: '2457089202',
        reason: '1, 3',
        reasonText: '<p>1. Уклонение победителя закупки от&nbsp;заключения договора с&nbsp;ПАО &laquo;ГМК &laquo;Норильский никель&raquo; или российской организацией корпоративной структуры, входящей в&nbsp;Группу компаний &laquo;Норильский никель&raquo;.</p><p>3. Нарушение условий договора, повлекшее за&nbsp;собой причинение убытков, начисление\n' +
        'неустойки либо возникновение иных требований ПАО &laquo;ГМК &laquo;Норильский никель&raquo;\n' +
        'или российской организации корпоративной структуры, входящей в&nbsp;Группу компаний &laquo;Норильский\n' +
        'никель&raquo;, к&nbsp;контрагенту, не&nbsp;урегулированных во&nbsp;внесудебном порядке,\n' +
        'установленном действующим законодательством либо договором.</p>',
        date: '25.07.2023'
    },
    {
        name: 'Общество с ограниченной ответственностью "Маяк"',
        inn: '5117064439',
        reason: '3',
        reasonText: '<p>3. Нарушение условий договора, повлекшее за&nbsp;собой причинение убытков, начисление\n' +
        'неустойки либо возникновение иных требований ПАО &laquo;ГМК &laquo;Норильский никель&raquo;\n' +
        'или российской организации корпоративной структуры, входящей в&nbsp;Группу компаний &laquo;Норильский\n' +
        'никель&raquo;, к&nbsp;контрагенту, не&nbsp;урегулированных во&nbsp;внесудебном порядке,\n' +
        'установленном действующим законодательством либо договором.</p>',
        date: '13.07.2023'
    },
    {
        name: 'Общество с ограниченной ответственностью "НОВА"',
        inn: '6168111270',
        reason: '1',
        reasonText: '<p>1. Уклонение победителя закупки от&nbsp;заключения договора с&nbsp;ПАО &laquo;ГМК &laquo;Норильский никель&raquo; или российской организацией корпоративной структуры, входящей в&nbsp;Группу компаний &laquo;Норильский никель&raquo;.</p>',
        date: '10.07.2023'
    },
    {
        name: 'ООО Инжиниринговая группа «Лемар»',
        inn: '6658470292',
        reason: '3',
        reasonText: '<p>3. Нарушение условий договора, повлекшее за&nbsp;собой причинение убытков, начисление\n' +
        'неустойки либо возникновение иных требований ПАО &laquo;ГМК &laquo;Норильский никель&raquo;\n' +
        'или российской организации корпоративной структуры, входящей в&nbsp;Группу компаний &laquo;Норильский\n' +
        'никель&raquo;, к&nbsp;контрагенту, не&nbsp;урегулированных во&nbsp;внесудебном порядке,\n' +
        'установленном действующим законодательством либо договором.</p>',
        date: '10.07.2023'
    },
    {
        name: 'ООО «ПЧ 17»',
        inn: '2461124530',
        reason: '3',
        reasonText: '<p>3. Нарушение условий договора, повлекшее за&nbsp;собой причинение убытков, начисление\n' +
        'неустойки либо возникновение иных требований ПАО &laquo;ГМК &laquo;Норильский никель&raquo;\n' +
        'или российской организации корпоративной структуры, входящей в&nbsp;Группу компаний &laquo;Норильский\n' +
        'никель&raquo;, к&nbsp;контрагенту, не&nbsp;урегулированных во&nbsp;внесудебном порядке,\n' +
        'установленном действующим законодательством либо договором.</p>',
        date: '18.05.2023'
    },
    {
        name: 'ООО «КСД-Петролиум»',
        inn: '5190175527',
        reason: '3',
        reasonText: '<p>3. Нарушение условий договора, повлекшее за&nbsp;собой причинение убытков, начисление\n' +
        'неустойки либо возникновение иных требований ПАО &laquo;ГМК &laquo;Норильский никель&raquo;\n' +
        'или российской организации корпоративной структуры, входящей в&nbsp;Группу компаний &laquo;Норильский\n' +
        'никель&raquo;, к&nbsp;контрагенту, не&nbsp;урегулированных во&nbsp;внесудебном порядке,\n' +
        'установленном действующим законодательством либо договором.</p>',
        date: '18.05.2023'
    },
    {
        name: 'АО «СТРОЙНЕФТЬКОМПЛЕКТ»',
        inn: '7451204602',
        reason: '3',
        reasonText: '<p>3. Нарушение условий договора, повлекшее за&nbsp;собой причинение убытков, начисление\n' +
        'неустойки либо возникновение иных требований ПАО &laquo;ГМК &laquo;Норильский никель&raquo;\n' +
        'или российской организации корпоративной структуры, входящей в&nbsp;Группу компаний &laquo;Норильский\n' +
        'никель&raquo;, к&nbsp;контрагенту, не&nbsp;урегулированных во&nbsp;внесудебном порядке,\n' +
        'установленном действующим законодательством либо договором.</p>',
        date: '16.05.2023'
    },
    {
        name: 'ООО «ТЕМП»',
        inn: '7813664709',
        reason: '1',
        reasonText: '<p>1. Уклонение победителя закупки от&nbsp;заключения договора с&nbsp;ПАО &laquo;ГМК &laquo;Норильский никель&raquo; или российской организацией корпоративной структуры, входящей в&nbsp;Группу компаний &laquo;Норильский никель&raquo;.</p>',
        date: '04.05.2023'
    },
    {
        name: 'ООО «ГК «Эффективные технологии»',
        inn: '6685087339',
        reason: '3',
        reasonText: '<p>3. Нарушение условий договора, повлекшее за&nbsp;собой причинение убытков, начисление\n' +
        'неустойки либо возникновение иных требований ПАО &laquo;ГМК &laquo;Норильский никель&raquo;\n' +
        'или российской организации корпоративной структуры, входящей в&nbsp;Группу компаний &laquo;Норильский\n' +
        'никель&raquo;, к&nbsp;контрагенту, не&nbsp;урегулированных во&nbsp;внесудебном порядке,\n' +
        'установленном действующим законодательством либо договором.</p>',
        date: '05.04.2023'
    },
    {
        name: 'ООО «Энергосервис»',
        inn: '2457057049',
        reason: '3',
        reasonText: '<p>3. Нарушение условий договора, повлекшее за&nbsp;собой причинение убытков, начисление\n' +
        'неустойки либо возникновение иных требований ПАО &laquo;ГМК &laquo;Норильский никель&raquo;\n' +
        'или российской организации корпоративной структуры, входящей в&nbsp;Группу компаний &laquo;Норильский\n' +
        'никель&raquo;, к&nbsp;контрагенту, не&nbsp;урегулированных во&nbsp;внесудебном порядке,\n' +
        'установленном действующим законодательством либо договором.</p>',
        date: '05.04.2023'
    },
    {
        name: 'ООО «ЕСУ»',
        inn: '6670397803',
        reason: '3',
        reasonText: '<p>3. Нарушение условий договора, повлекшее за&nbsp;собой причинение убытков, начисление\n' +
        'неустойки либо возникновение иных требований ПАО &laquo;ГМК &laquo;Норильский никель&raquo;\n' +
        'или российской организации корпоративной структуры, входящей в&nbsp;Группу компаний &laquo;Норильский\n' +
        'никель&raquo;, к&nbsp;контрагенту, не&nbsp;урегулированных во&nbsp;внесудебном порядке,\n' +
        'установленном действующим законодательством либо договором.</p>',
        date: '04.04.2023'
    },
    {
        name: 'ООО «Теплый дом»',
        inn: '5108003729',
        reason: '3',
        reasonText: '<p>3. Нарушение условий договора, повлекшее за&nbsp;собой причинение убытков, начисление\n' +
        'неустойки либо возникновение иных требований ПАО &laquo;ГМК &laquo;Норильский никель&raquo;\n' +
        'или российской организации корпоративной структуры, входящей в&nbsp;Группу компаний &laquo;Норильский\n' +
        'никель&raquo;, к&nbsp;контрагенту, не&nbsp;урегулированных во&nbsp;внесудебном порядке,\n' +
        'установленном действующим законодательством либо договором.</p>',
        date: '03.04.2023'
    },
    {
        name: 'ООО «Дизельзипсервис»',
        inn: '7816464828',
        reason: '3',
        reasonText: '<p>3. Нарушение условий договора, повлекшее за&nbsp;собой причинение убытков, начисление\n' +
        'неустойки либо возникновение иных требований ПАО &laquo;ГМК &laquo;Норильский никель&raquo;\n' +
        'или российской организации корпоративной структуры, входящей в&nbsp;Группу компаний &laquo;Норильский\n' +
        'никель&raquo;, к&nbsp;контрагенту, не&nbsp;урегулированных во&nbsp;внесудебном порядке,\n' +
        'установленном действующим законодательством либо договором.</p>',
        date: '27.03.2023'
    },
    {
        name: 'ООО «ВОЛЬТА РУС»',
        inn: '7811552911',
        reason: '3',
        reasonText: '<p>3. Нарушение условий договора, повлекшее за&nbsp;собой причинение убытков, начисление\n' +
        'неустойки либо возникновение иных требований ПАО &laquo;ГМК &laquo;Норильский никель&raquo;\n' +
        'или российской организации корпоративной структуры, входящей в&nbsp;Группу компаний &laquo;Норильский\n' +
        'никель&raquo;, к&nbsp;контрагенту, не&nbsp;урегулированных во&nbsp;внесудебном порядке,\n' +
        'установленном действующим законодательством либо договором.</p>',
        date: '16.03.2023'
    },
    {
        name: 'ООО «Среднерусская Тепловозоремонтная Компания»',
        inn: '5022052241',
        reason: '3',
        reasonText: '<p>3. Нарушение условий договора, повлекшее за&nbsp;собой причинение убытков, начисление\n' +
        'неустойки либо возникновение иных требований ПАО &laquo;ГМК &laquo;Норильский никель&raquo;\n' +
        'или российской организации корпоративной структуры, входящей в&nbsp;Группу компаний &laquo;Норильский\n' +
        'никель&raquo;, к&nbsp;контрагенту, не&nbsp;урегулированных во&nbsp;внесудебном порядке,\n' +
        'установленном действующим законодательством либо договором.</p>',
        date: '02.02.2023'
    },
    {
        name: 'ООО «Инстар Лоджистикс»',
        inn: '7714136948',
        reason: '3',
        reasonText: '<p>3. Нарушение условий договора, повлекшее за&nbsp;собой причинение убытков, начисление\n' +
        'неустойки либо возникновение иных требований ПАО &laquo;ГМК &laquo;Норильский никель&raquo;\n' +
        'или российской организации корпоративной структуры, входящей в&nbsp;Группу компаний &laquo;Норильский\n' +
        'никель&raquo;, к&nbsp;контрагенту, не&nbsp;урегулированных во&nbsp;внесудебном порядке,\n' +
        'установленном действующим законодательством либо договором.</p>',
        date: '30.01.2023'
    },
    {
        name: 'ООО «Берег»',
        inn: '2403002886',
        reason: '1',
        reasonText: '<p>1. Уклонение победителя закупки от&nbsp;заключения договора с&nbsp;ПАО &laquo;ГМК &laquo;Норильский никель&raquo; или российской организацией корпоративной структуры, входящей в&nbsp;Группу компаний &laquo;Норильский никель&raquo;.</p>',
        date: '18.01.2023'
    },
    {
        name: 'ООО «Кью-Воркс»',
        inn: '9718101530',
        reason: '3',
        reasonText: '<p>3. Нарушение условий договора, повлекшее за&nbsp;собой причинение убытков, начисление\n' +
        'неустойки либо возникновение иных требований ПАО &laquo;ГМК &laquo;Норильский никель&raquo;\n' +
        'или российской организации корпоративной структуры, входящей в&nbsp;Группу компаний &laquo;Норильский\n' +
        'никель&raquo;, к&nbsp;контрагенту, не&nbsp;урегулированных во&nbsp;внесудебном порядке,\n' +
        'установленном действующим законодательством либо договором.</p>',
        date: '13.01.2023'
    }
]
