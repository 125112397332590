/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'arrow-long': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<g clip-path="url(#clip0_9226_31640)"><path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M11.84 4.125a.498.498 0 00-.196.121l-7.398 7.398a.5.5 0 10.707.707l6.545-6.544v13.588a.5.5 0 101 0V5.807l6.543 6.544a.5.5 0 00.708-.707l-7.392-7.392a.498.498 0 00-.518-.127z" _fill="#0077C8"/></g><defs><clipPath id="clip0_9226_31640"><path pid="1" _fill="#fff" d="M0 0h24v24H0z"/></clipPath></defs>'
  }
})
