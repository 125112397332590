<template>
    <div
        :class="['report-block', { 'report-block--horizontal': horizontal }]"
        v-if="data && data.name"
    >
        <component
            :is="defineReportComponent(getCoverLink)"
            :href="getCoverLink"
            class="report-block__image"
            :target="getIsOpenInNewTab"
            :style="`background-image: url(${data.image})`"
            @click="handlerTrackGoal(goals.goalNameBlockImage)"/>
        <div class="report-block__info">
            <p class="u-bottom-margin--2xs u-bold">
                <vue-raw :raw="data.name"/>
            </p>
            <div class="report-block__links-wrapper">
                <div v-if="data.onlinelink || data.fileLink || data.learnMoreLink" class="report-block__links">
                    <z-button
                        v-if="data.fileLink"
                        class="report-block__link report-block__link--pdf"
                        tag="a"
                        :href="data.fileLink"
                        target="_blank"
                        kind="secondary"
                        rounded
                        size="s"
                        @click="handlerTrackGoal(goals.goalNameBtnPdf)">
                        <span class="u-bold">PDF</span>
                    </z-button>
                    <z-button
                        v-if="data.onlinelink"
                        class="report-block__link report-block__link--online"
                        tag="a"
                        :href="data.onlinelink"
                        target="_blank"
                        showArrowOnHover
                        kind="secondary"
                        size="s"
                        rounded
                        @click="handlerTrackGoal(goals.goalNameBtnOnline)">
                        <span v-html="text.onSite"></span>
                    </z-button>
                    <z-button
                        v-if="data.brochure"
                        class="report-block__link report-block__link--brochure"
                        tag="a"
                        :href="data.brochure"
                        target="_blank"
                        kind="secondary"
                        size="s"
                        rounded>
                        <span v-html="text.brochure"></span>
                    </z-button>
                    <z-button
                        v-if="data.learnMoreLink"
                        tag="a"
                        :href="data.learnMoreLink"
                        :target="getIsOpenInNewTab"
                        kind="secondary"
                        size="s"
                        @click="handlerTrackGoal(goals.goalNameBtnDetails)">
                        {{ text.learnMore }}
                    </z-button>
                    <z-button
                        v-if="data.taggedFileLink && !data.onlinelink"
                        class="report-block__link report-block__link--xbrl"
                        tag="a"
                        :href="data.taggedFileLink"
                        target="_blank"
                        showArrowOnHover
                        kind="secondary"
                        size="s"
                        rounded
                        @click="handlerTrackGoal(goals.goalNameBtnXbrl)">
                        <span class="u-bold">XBRL</span>
                    </z-button>
                </div>
                <z-button
                    v-if="data.taggedFileLink && data.onlinelink"
                    class="report-block__link report-block__link--xbrl"
                    tag="a"
                    :href="data.taggedFileLink"
                    target="_blank"
                    showArrowOnHover
                    kind="secondary"
                    size="s"
                    rounded
                    @click="handlerTrackGoal(goals.goalNameBtnXbrl)">
                    <span class="u-bold">XBRL</span>
                </z-button>
                <slot />
            </div>
        </div>
    </div>
</template>

<script>
import { localize } from '@/utils/i18n'
import { trackGoal } from '@/utils/analytics'

export default {
    name: 'report-block',
    data () {
        return {
            text: {
                onSite: localize({
                    ru: 'Онлайн',
                    en: 'Online'
                }),
                learnMore: localize({
                    ru: 'Подробнее',
                    en: 'Learn more'
                }),
                brochure: localize({
                    ru: 'Брошюра',
                    en: 'Brochure'
                })
            }
        }
    },
    props: {
        data: {
            type: Object,
            default: () => ({
                image: '',
                name: '',
                fileLink: '',
                onlinelink: '',
                taggedFileLink: '',
                learnMoreLink: ''
            })
        },
        horizontal: {
            type: Boolean,
            default: false
        },
        isTargetBlank: {
            type: Boolean,
            default: true
        },
        goals: {
            type: Object,
            default: () => ({
                goalNameBlockImage: '',
                goalNameBtnOnline: '',
                goalNameBtnPdf: '',
                goalNameBtnXbrl: '',
                goalNameBtnDetails: ''
            })
        }
    },
    computed: {
        getIsOpenInNewTab () {
            return this.isTargetBlank && '_blank'
        },
        getCoverLink () {
            return this.data.onlinelink || this.data.learnMoreLink
        }
    },
    methods: {
        defineReportComponent (link) {
            if (!link) {
                return 'div'
            } else {
                return 'a'
            }
        },
        handlerTrackGoal (goalName) {
            trackGoal(goalName)
        }
    }
}
</script>

<style lang="scss">
.report-block {
    $parent: &;
    $custom-transition: 0.4s ease-in-out;
    display: flex;
    flex-direction: column;
    max-width: 210px;
    transition: transform $custom-transition;
    transform-origin: top left;

    @include breakpoint (mobile) {
        max-width: 100%;
        margin-top: $token-spacers-xs;
    }

    &__info {
        line-height: 1.4;
    }

    &__info p {
        transition: transform $custom-transition, color $custom-transition;
        transform-origin: center left;
    }

    &:hover {
        transform: scale(1.05);

        @include breakpoint(mobile) {
            transform: scale(1);
        }

        .report-block__links-wrapper {
            transform: scale(0.95);
        }

        .report-block__image {
            box-shadow: 4px 18px 40px 0px rgba(14, 93, 140, 0.5), 4px 6px 12px 0px #5a93b9;
        }
    }

    &__image {
        max-width: 210px;
        position: relative;
        background-size: auto 100%;
        background-repeat: no-repeat;
        background-position: 100% 0;
        flex-shrink: 0;
        box-shadow: -16px 9px 22px -2px rgba(42, 75, 99, 0.1);
        margin-bottom: $token-spacers-s;
        width: 100%;
        transition: transform $custom-transition, box-shadow $custom-transition;

        &:before {
            padding-top: 132%;
            content: '';
            width: 100%;
            background-image: url(/images/book-cover.png);
            background-repeat: no-repeat;
            background-size: cover;
            background-position: 50% 50%;
            display: block;
        }

        @include breakpoint (mobile) {
            max-width: 100%;
        }
    }

    &__links-wrapper {
        display: flex;
        flex-direction: column;
        gap: $token-spacers-3-xs;
        transition: transform $custom-transition;
        transform-origin: left top;
    }

    &__links {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        gap: $token-spacers-3-xs;
        margin-bottom: 0 !important;
    }

    &__link.z-button {
        padding: 8px 10px;
        // border-radius: calc(35px / 2);
        border-radius: 20px;

        span {
            font-size: 12px;
            display: flex;
            align-items: center;
        }
    }

    &__link--xbrl.z-button,
    &__link--pdf.z-button {
        width: fit-content;
        height: fit-content;
        padding: 8px 10px !important;

        span {
            font-size: 14px;
            line-height: 1.4;
            letter-spacing: 0.1em;
        }

        // @include breakpoint(mobile) {
        //     width: initial !important;
        // }
    }

    &__link--online.z-button,
    &__link--brochure.z-button {
        width: fit-content;
        span {
            font-size: 14px;
            text-transform: uppercase;
            line-height: 1.3;
        }
    }

    &--horizontal {
        @media (min-width: #{$breakpoint-mobile}) {
            width: auto;
            flex-direction: row;
            align-items: center;

            #{$parent}__info {
                @include padding-level(left, S);
                max-width: 260px;
                width: 100%;
                flex-shrink: 0;
            }
        }
    }
}
</style>
