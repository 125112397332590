<template>
    <div :class="['u-bottom-margin--3xl', { container: device === 'desktop' }]">
        <section class="mp-career">
            <div class="mp-career__left">
                <div class="mp-career__left-content">
                    <z-caption
                        :href="device === 'mobile' ? false : '/careers/why-nornickel/'"
                        :tag="device === 'mobile' ? 'h2' : 'a'"
                        size="3xl"
                        decor-left
                        decor-right
                        class="main-page__title u-bottom-margin--m-important"
                        @click="handlerTrackGoal('main-8screen-careers_block')"
                    >
                        <span v-html="career.title" data-scroll-heading></span>
                    </z-caption>

                    <div class="mp-career__wrapper">
                        <div class="mp-career__response" data-scroll-translate>
                            <p
                                class="mp-career__info-title"
                                v-html="career.response.title"></p>
                            <vue-raw
                                class="mp-career__info-text"
                                :raw="career.response.text.main"></vue-raw>
                            <p
                                class="mp-career__info-text"
                                v-if="lang == 'ru'">
                                <span class="mp-career__info-text mp-career__info-text--small">
                                    {{ career.response.contacts }}
                                </span>
                                <br />
                                <z-link
                                    class="mp-career__info-text mp-career__info-text--tel"
                                    href="tel: +7(800)7001943"
                                    @click="handlerTrackGoal('main-8screen-careers_block')"
                                    >
                                    <span>8 800 700 19 43</span>
                                </z-link>
                            </p>
                        </div>

                        <div
                            class="mp-career__reviews"
                            v-if="lang == 'ru'"
                            data-scroll-translate
                        >
                            <contact-card
                                photo="/images/main-page/career.jpg"
                                class="mp-career__info-card">
                                <template v-slot:name>
                                    <span v-html="career.reviews.title"></span>
                                </template>
                            </contact-card>
                            <p
                                class="mp-career__info-text"
                                v-html="career.reviews.text"></p>
                            <z-link
                                href="/careers/encouraged-people/"
                                class="mp-career__info-text--small"
                                @click="handlerTrackGoal('main-8screen-careers_block')">
                                <template v-slot:right>
                                    <z-icon
                                        name="arrow-long"
                                        dir="right"
                                        width="24"
                                        height="24"></z-icon>
                                </template>
                                <span>{{ career.reviews.button }}</span>
                            </z-link>
                        </div>
                        <div
                            class="col-default-6 col-mobile-12 hide-me--tablet show-me--mobile"
                            v-else>
                            <z-image src="/images/main-page/career--en.png"></z-image>
                        </div>
                    </div>
                </div>
            </div>
            <div class="mp-career__right">
                <div class="mp-career__links">
                    <z-caption
                        tag="h2"
                        size="m"
                        weight="700"
                        decor-left
                        decor-right
                        class="main-page__title"
                        uppercase
                    >
                        <span data-scroll-heading>{{ career.links.title }}</span>
                    </z-caption>

                    <div class="mp-career__links-container u-top-margin--2xl" data-scroll-translate>
                        <div
                            class="mp-career__links-item"
                            v-if="lang == 'ru'">
                            <div class="mp-career__links-icon">
                                <z-icon
                                    name="arrow-long"
                                    width="16"
                                    height="16"
                                    dir="right" />
                            </div>
                            <p class="mp-career__links-text">
                                {{ career.links.vacancies.start }}
                                <z-link href="/careers/vacancies/" @click="handlerTrackGoal('main-8screen-careers_block')">
                                    <span>{{ career.links.vacancies.link }}</span>
                                </z-link>
                                {{ career.links.vacancies.end }}
                            </p>
                        </div>
                        <div class="mp-career__links-item">
                            <div class="mp-career__links-icon">
                                <z-icon
                                    name="arrow-long"
                                    width="16"
                                    height="16"
                                    dir="right" />
                            </div>
                            <vue-raw
                                class="mp-career__links-text"
                                :raw="career.links.participation.text"></vue-raw>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
import { mixinDevice } from '@/utils/mixin'
import { localize } from '@/utils/i18n'
import { trackGoal } from '@/utils/analytics'

export default {
    name: 'section-career',
    mixins: [mixinDevice],
    data () {
        return {
            lang: this.$root.lang,
            career: {
                title: localize({
                    ru: 'Карьера',
                    en: 'Careers'
                }),
                response: {
                    title: localize({
                        ru: 'Отправь отклик и&nbsp;расскажи нам о&nbsp;себе',
                        en: 'Send&nbsp;us a&nbsp;CV'
                    }),
                    text: {
                        main: localize({
                            ru: `Пришли свое резюме на электронный адрес: <z-link href="mailto:hr@nornik.ru"><span>hr@nornik.ru</span></z-link> или <z-link href="https://hr2.nornik.ru/ispecialist/questionnaire/?site=true"><span>заполни анкету</span></z-link>`,
                            en: `Write to: <z-link href="mailto:hr@nornik.ru"><span>hr@nornik.ru</span></z-link>`
                        })
                    },
                    contacts: localize({
                        ru: 'Бесплатный номер для соискателей',
                        en: 'Бесплатный номер для соискателей'
                    })
                },
                reviews: {
                    title: 'Увлеченные делом: сотрудники о&nbsp;работе в&nbsp;«Норникеле»',
                    text: 'Как расти и&nbsp;развиваться в&nbsp;компании рассказывают наши сотрудники',
                    button: localize({
                        ru: 'Подробнее',
                        en: 'Learn more'
                    })
                },
                links: {
                    title: localize({
                        ru: 'Ссылки',
                        en: 'Links'
                    }),
                    vacancies: {
                        start: 'Посмотреть',
                        link: 'вакансии',
                        end: 'в вашем регионе'
                    },
                    participation: {
                        text: localize({
                            ru: `Принять участие в <z-link href="/careers/beginning/"><span>специальных программах для молодых специалистов</span></z-link>`,
                            en: `Realise your <z-link href="/careers/beginning/"><span>full potential</span></z-link> with us`
                        })
                    }
                }
            }
        }
    },
    methods: {
        handlerTrackGoal (goalName) {
            trackGoal(goalName)
        }
    }
}
</script>

<style lang="scss">
.mp-career {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    background-color: #f5f5f5;
    background: linear-gradient(110.75deg, #f4f6f9 23.84%, #e6e9ee 100%), #f5f5f5;

    @include breakpoint(tablet) {
        background-image: none;
        padding: 0 40px;
        background: linear-gradient(112.26deg, #f4f6f9 8.89%, #e6e7ee 62.24%);
    }

    @include breakpoint(mobile) {
        padding: 0;
        padding-right: 12px;
        background: linear-gradient(110.75deg, #f4f6f9 23.84%, #e6e9ee 100%), #f5f5f5;
    }

    &__wrapper {
        display: flex;
        justify-content: space-between;

        @include breakpoint(v-tablet) {
            flex-direction: column;
            gap: $token-spacers-l;
        }
    }

    &__response {
        max-width: 390px;
        width: 50%;

        @include breakpoint(v-tablet) {
            max-width: 100%;
            width: 100%;
        }
    }

    &__reviews {
        width: 46%;

        @include breakpoint(v-tablet) {
            width: 100%;
        }
    }

    &__left {
        width: 66.66%;
        @include padding-level(top, XL);
        @include padding-level(bottom, 2XL);

        @include breakpoint(tablet) {
            width: 100%;
            padding-bottom: 0;
        }

        .z-caption__text {
            @include breakpoint(mobile) {
                @include margin-level(left, 2XS, false, true);
            }
        }
    }

    &__left-content {
        padding-right: $token-grids-desktop-offset;
        padding-left: $token-grids-desktop-offset;

        @include breakpoint(laptop) {
            padding-left: $token-grids-laptop-offset;
            padding-right: $token-grids-laptop-offset;
        }

        @include breakpoint(tablet) {
            padding-right: 0;
            padding-left: 0;
        }

        @include breakpoint(mobile) {
            padding-left: $token-grids-mobile-offset;
            padding-right: $token-grids-mobile-offset;

            .z-caption--decor-left.z-caption--decor-right .z-caption__text {
                margin-left: 0 !important;
            }
        }
    }

    &__right {
        width: 33.33%;
        background-image: url(/images/main-page/book-cover.png);
        background-repeat: no-repeat;
        background-size: 40% 100%;
        background-position: 0% 50%;
        padding-left: $token-grids-desktop-offset;
        padding-right: $token-grids-desktop-offset;
        @include padding-level(top, 2XL);
        @include padding-level(bottom, 2XL);

        @include breakpoint(laptop) {
            padding-right: $token-grids-laptop-offset;
        }

        @include breakpoint(tablet) {
            padding-right: 0;
            padding-left: 0;
            background-image: none;
            width: 100%;
            @include padding-level(bottom, M);
        }

        @include breakpoint(mobile) {
            padding-left: $token-grids-mobile-offset;
            padding-right: $token-grids-mobile-offset;
        }
    }

    &__info {
        display: flex;
        align-items: flex-start;
        flex-wrap: nowrap;

        @include breakpoint(mobile) {
            flex-direction: column;
        }
    }
    &__info-title {
        font-weight: 600;
        font-size: 22px;
        line-height: 140%;
        color: $token-colors-gray-60;
        margin-bottom: $token-spacers-m;

        @include breakpoint(v-tablet) {
            margin-bottom: $token-spacers-xs;
        }

            @include breakpoint(mobile) {
            margin-bottom: $token-spacers-xs;
            font-size: 18px;
        }
    }

    &__info-item:not(:last-child) {
        margin-right: 106px;

        @media screen and(max-width: 1760px) {
            margin-bottom: $token-spacers-xs;
        }

        @include breakpoint(mobile) {
            margin-right: 0;
        }
    }

    &__info-text {
        font-weight: 400;
        font-size: 20px;
        line-height: 140%;
        color: $token-colors-black;
        margin-bottom: $token-spacers-2-xs;
        max-width: 410px;

        @include breakpoint(v-tablet) {
            max-width: 100%;
        }

        @include breakpoint(mobile) {
            font-size: 18px;
            max-width: 410px;
        }

        &--tel {
            text-transform: uppercase;
        }

        &--small {
            @include typo-level(S);
        }
    }

    &__info-card {
        max-width: none;

        @include breakpoint(mobile) {
            margin-bottom: $token-spacers-s;
        }
    }

    &__links {
        @include padding-level(left, 2XS);

        @include breakpoint(laptop) {
            padding-left: $token-grids-laptop-offset;
        }

        @include breakpoint(tablet) {
            padding-left: 0;
        }
    }
    &__links-container {
        display: flex;
        flex-direction: column;

        @include breakpoint(tablet) {
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: space-between;
            margin-top: $token-spacers-m;
        }

        @include breakpoint(v-tablet) {
            flex-direction: column;
            align-items: flex-start;
            margin-top: $token-spacers-s;
        }

        @include breakpoint(mobile) {
            margin-top: 0;
        }
    }

    &__links-item {
        display: flex;
        align-items: start;
        flex: 1 0 50%;
        margin-bottom: $token-spacers-xs;
        @include margin-level(bottom, M);

        @include breakpoint(tablet) {
            flex: none;

            &:first-child {
                width: 50%;
            }

            &:nth-child(2n) {
                width: 46%;
            }
        }

        @include breakpoint(v-tablet) {
            &:first-child, &:nth-child(2n) {
                width: 100%;
            }
        }

            img {
            margin-right: $token-spacers-2-xs;
        }

        &:hover {
            .mp-career__links-icon {
                background-color: $token-colors-navy-blue;
                border-color: $token-colors-navy-blue;

                .z-icon path {
                    fill: $token-colors-white;
                }
            }
        }
    }

    &__links-icon {
        width: 36px;
        height: 36px;
        flex-shrink: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        border: 1px solid $token-colors-link-defolt-defolt;
        transition: background-color 0.2s ease-out, border-color 0.2s ease-out;
        margin-right: 16px;

        @include breakpoint(v-tablet) {
            width: 32px;
            height: 32px;
        }

        .z-icon path {
            fill: $token-colors-link-defolt-defolt;
        }
    }
}
</style>
